<template>
 <div>
   <v-card class="pa-2 pl-4 mb-3 elevation-0">
    <h2>Branch Management</h2>
   </v-card>
   
   <v-toolbar  class="elevation-2"  color="white">
       <v-text-field
        v-model="globalSearch"
        class="va-form-control"
        append-icon="search"
        label=""
        @keyup="onSearch()"
        solo
        placeholder="Search"
     />
     <v-spacer></v-spacer>
      <v-spacer></v-spacer>
        <v-dialog persistent v-model="dialog" max-width="900px">
        <v-btn slot="activator" color="primary" dark class="mb-2">New Branch</v-btn>
        <v-card>
          <v-card-title>
            <span class="headline">
              {{ formTitle }}
            </span>
           
          </v-card-title>

          <v-card-text>
            
            <v-alert v-if="message.visible" :value="true" :type="message.type" style="margin: -20px 20px -20px 20px; padding: 10px">
              <b>{{message.title}}</b> {{message.content}}
            </v-alert>
            <v-container grid-list-md style="margin: -20px 0 -20px 0">
              <v-layout wrap row>
                <v-flex xs12 md6 lg6>
                    <va-input
                      class="mr-2"
                      v-model="editedItem.branch_code"
                      v-validate="'required|min:1|max:50'"
                      data-vv-scope="branch"
                      :error-messages="errors.collect('branch.branch_code')"
                      placeholder=" "
                      label="Branch Code"
                      :max-width="'420px'"  
                      data-vv-validate-on="blur"         
                      data-vv-name="branch_code"
                      show-clean />

                  <va-input
                    v-model="editedItem.branch_name"
                    v-validate="'required|min:3|max:50'"
                    data-vv-scope="branch"
                    :error-messages="errors.collect('branch.branch_name')"
                    placeholder=" "
                    label="Branch Name"
                    :max-width="'420px'"  
                    data-vv-validate-on="blur"         
                    data-vv-name="branch_name"
                    show-clean />

                  <va-input
                    v-validate="{ required: true, regex:/^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/}"
                    v-model="editedItem.branch_telephone"  
                    placeholder=" "
                    data-vv-scope="branch"
                    label="Contact Number"
                    data-vv-name="branch_telephone"
                    :max-width="'420px'"
                    :return-masked-value="true"
                    :persistent-hint="true"
                    :hint="'Expected Format: 000 000 0000'"
                    :mask="'(###) ###-####'"
                    :error-messages="errors.collect('branch.branch_telephone')"
                    />
                  <va-input
                    v-model="editedItem.branch_email"
                    v-validate="'required|min:3|max:50'"
                    data-vv-scope="branch"
                    :error-messages="errors.collect('branch.branch_email')"
                    placeholder=" "
                    label="Branch Email"
                    :max-width="'420px'"  
                    data-vv-validate-on="blur"         
                    data-vv-name="branch_email"
                    show-clean />
                   <v-layout row >                   
                    <va-select 
                      class="mr-3"
                      v-model="editedItem.branch_scope"
                      v-validate="'required'"
                      placeholder=" "
                      label="Branch Scope"
                      data-vv-scope="branch"
                      :error-messages="errors.collect('branch.branch_type')"
                      :max-width="'420px'"
                      :items="branchScope"
                      :item-text="'text'"
                      :item-value="'value'"
                      data-vv-name="branch_type"/>
                      <va-select 
                      v-model="editedItem.branch_risk_factor_group"
                      placeholder="Select an option"
                      label="Risk Factor Group"
                      :max-width="'420px'"
                      :error-messages="errors.collect('branch.branch_risk_factor_group')"
                      :items="groups"
                      :item-text="'text'"
                      :item-value="'value'"
                      data-vv-name="branch_risk_factor_group"/>
                  </v-layout>
                  
                </v-flex>

                <v-flex xs12 md6 lg6>
                  <va-select 
                    v-model="editedItem.branch_country"
                    v-validate="'required'" 
                    placeholder="Select an option"
                    label="Country"
                    :max-width="'420px'"
                    @change="onCountryChanged($event)"
                    :error-messages="errors.collect('branch.branch_country')"
                    :items="commonState.countries"
                    :item-text="'name'"
                    :item-value="'name'"
                    data-vv-name="branch_country"/>
                
                  <va-select 
                      :disabled="!editedItem.branch_country"
                      v-model="editedItem.branch_province"
                      v-validate="'required'"
                      placeholder="Select an option"
                      label="Province/State"
                      data-vv-scope="branch"
                      :error-messages="errors.collect('branch.branch_province')"
                      :max-width="'420px'"
                      :items="provinces"
                      data-vv-name="branch_province"/>

                  <va-input
                    v-model="editedItem.branch_city"
                    v-validate="'required|min:3|max:50'"
                    data-vv-scope="branch"
                    :error-messages="errors.collect('branch.branch_city')"
                    placeholder=" "
                    label="City/Town"
                    :max-width="'420px'"  
                    data-vv-validate-on="blur"         
                    data-vv-name="branch_city"
                    show-clean />
              
                  <va-input
                    v-model="editedItem.branch_street"
                    v-validate="'required|min:3|max:100'"
                    :error-messages="errors.collect('branch.branch_street')"
                    data-vv-scope="branch"
                    placeholder=" "
                    label="Street"
                    :max-width="'420px'"   
                    data-vv-validate-on="blur"        
                    data-vv-name="branch_street"
                    show-clean />
                   <v-layout row >
                      <va-select 
                        class="pr-4"
                        v-model="editedItem.branch_currency"
                        v-validate="'required'" 
                        data-vv-scope="branch"
                        placeholder="Select an option"
                        label="Default Currency"
                        data-vv-validate-on="blur"
                        :items="commonState.currencies"
                        :item-text="'name'"
                        :item-value="'code'"
                        :error-messages="errors.collect('branch.default_currency')"
                        data-vv-name="default_currency"/>
                    
                      <va-select 
                        v-model="editedItem.branch_timezone"
                        v-validate="'required'" 
                        data-vv-scope="branch"
                        placeholder="Select an option"
                        label="Default Timezone"
                        :error-messages="errors.collect('branch.timezone')"
                        data-vv-validate-on="blur"
                        :items="getTimezonesAndOffsets()"
                        :item-text="'format'"
                        :item-value="'zone'"
                        data-vv-name="timezone"/>
                  </v-layout>
         
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>

          <v-card-actions style="margin-top:-30px" class="ml-4 mr-4">
            <v-spacer></v-spacer>
            <v-btn flat @click="close">Cancel</v-btn>
            <v-btn color="primary" @click="submit">Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      
    </v-toolbar>
    <v-data-table
      :headers="headers"
      class="elevation-1"
      :items="items"
      :loading="branchState.isLoading"
      :pagination.sync="pagination"
      :total-items="totalCount"
      :rows-per-page-items='[20, 40, 80,{"text":"$vuetify.dataIterator.rowsPerPageAll","value":-1}]'
    >
     <v-progress-linear slot="progress" color="blue" indeterminate></v-progress-linear>
      <template slot="items" slot-scope="props">
        <td class="justify-center layout">
        <v-switch
            v-model="props.item.is_active"
            label=""
            class="mt-2"
            @change="toggleActive(props.item)"
          ></v-switch>
        </td>
        <td>{{ props.item.branch_code }}</td>
        <td>{{ props.item.branch_name }}</td>
        <td class="text-xs-right">{{ props.item.branch_scope || 'Regular' }}</td>
        <td>{{ props.item.branch_risk_factor_group || '-' }}</td>
        <td>{{ props.item.branch_email }}</td>
        <!-- <td>{{ props.item.date_created }}</td> -->
        <td>
          <v-icon small class="mr-2" @click="onEditItemClicked(props.item)">
            edit
          </v-icon>
        </td>
      </template>
      <template slot="no-data">
        
      </template>
    </v-data-table>
  </div>
</template>

<style type="css" scoped>
h1, h2, h3 {
  color: #505a6b;}

</style>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
import VaInput from '@/components/form-components/VaInput.vue'
import VaSelect from '@/components/form-components/VaSelect.vue'
import PaginationMixin from '@/mixins/PaginationMixin'
import BranchService from '@/services/branch-service'
export default {
  components: {
    VaInput,
    VaSelect
  },

  mixins: [PaginationMixin],

  created () {
    this.initialize()
  },
  
  beforeDestroy() {
    this.$toast.destroy()
  },

  data: ()=> {
    return {
      groups: [],
      dialog: false,
      headers: [
        { text: 'Active', align: 'left', sortable: false, value: 'is_active'},
        { text: 'Branch code', align: 'left', sortable: true, value: 'branch_code'},
        { text: 'Branch Name', align: 'left', sortable: true, value: 'branch_name'},
        { text: 'Branch Type', align: 'left', sortable: true, value: 'branch_scope'},
        { text: 'Risk Group', align: 'left', sortable: true, value: 'branch_risk_factor_group'},
        { text: 'Email', align: 'left', sortable: false, value: 'branch_email'},
        // { text: 'Date Created', value: 'date_created' },
        { text: 'Action', value: ''},
      ],
      items: [],
      branchScope: [
        {value: 'Region', text:'Regional'}, 
        {value: 'Branch', text:'Regular'}
      ],
      editedIndex: -1,
      editedItem: {},
      provinces:[],
      message: {
        visible: false,
        title: '',
        content: '',
        type: 'success'
      }, 
    }
  },

  computed: {
    ...mapState([
      'appState',
      'commonState',
      'branchState',
      'riskFactorState'
    ]), 
    
    formTitle () {
      return this.editedIndex === -1 ? 'New Branch' : 'Edit Branch'
    }
  },

  methods: {
    ...mapGetters([
      'getTimezonesAndOffsets'
    ]),
    ...mapMutations([
      'SHOW_UNEXPECTED_ERROR_DIALOG'
    ]),
    ...mapActions([
      'getBranches',
      'createBranch',
      'updateBranch',
      'deleteBranch',
      'getRiskFactorGroups',
    ]),
    
    async initialize () {
      try{
        Object.assign(this.editedItem, this.branchState.branch)
        await this.getRiskFactorGroups()
        this.groups = this.riskFactorState.riskFactorGroups.map((el)=> ({value: el.group_name, text: el.group_name}) )    
        this.groups.push({value:'-', text: 'Default'})
      } catch(e) {
        console.log('saving/updating: ',e)
        this.SHOW_UNEXPECTED_ERROR_DIALOG(true)
      }
    },

    close () {
      this.dialog = false
      this.editedItem = Object.assign({}, this.branch)
      this.editedIndex = -1
      this.$validator.reset({scope: 'branch'});
    },

    onCountryChanged(ev) {
      this.provinces = []
      this.editedItem.branch_country = ev
      for(let key in this.commonState.countries) {
        if(this.commonState.countries[key].name === ev) {
          let divisions = []
          Object.keys(this.commonState.countries[key].divisions).forEach((keyx)=>{
            divisions.push(this.commonState.countries[key].divisions[keyx])
          })
          this.provinces =  divisions
          break
        }
      }
    },

    onEditItemClicked(item) {
      this.editedIndex = this.items.indexOf(item)
      this.editedItem = item
      this.onCountryChanged(item.branch_country)
      this.dialog = true
    },

    async fetchData() {
      try {
        this.resetState()
        let search = `branch_code=lk:${this.globalSearch}:or&branch_name=lk:${this.globalSearch}`

        await this.getBranches({filter: search, sort: this.sortString, paginate: {skip: this.skip, limit: this.limit}})
        this.items = this.branchState.branches 
        this.totalCount = this.branchState.totalCount  
      } catch(e) {
        console.log('fetching data: ', e)
        this.SHOW_UNEXPECTED_ERROR_DIALOG(true)
      } 
    },

    async submit() {
      this.resetState()
      let isValid = await this.$validator.validateAll("branch")
      if(isValid) {
        this.save()
      } else {
        this.$toast.error('Please check to ensure that all fields are valid.', '', {position: 'topCenter'})
      }
    },

    async save () {
      try {
        this.$toast.destroy();
        let exist = await this.doesRecordExist()
        if(exist) return

        if (this.editedIndex > -1) {
          await this.updateBranch({index: this.editedIndex, obj: this.editedItem})
          this.$toast.success('Successfully updated record!', '', {position: 'topCenter'})
        } else {
          await this.createBranch(this.editedItem)
          this.$toast.success('Successfully inserted record!', '', {position: 'topCenter'})
        }
        this.close()
       
      } catch(e) {
        console.log('saving/updating: ',e)
        this.SHOW_UNEXPECTED_ERROR_DIALOG(true)
      }
    },

    async toggleActive(item) {
      this.editedIndex = this.items.indexOf(item)
      this.editedItem = {...item}
      this.onCountryChanged(item.branch_country)
      try {
        await this.save()
      } catch (e) {
        item.is_active = !item.is_active
      }
    },

    async doesRecordExist() {
      let search = `branch_code=lk:${this.editedItem.branch_code}:or&branch_name=eq:${this.editedItem.branch_name}:or`
      let items = await BranchService.findAll(search, '&sort=branch_code:asc', {skip: 0, limit: 100})
      let msg = 'A record already exist with the following values: '
      let existProps = []

      items = items.data

      if (this.editedIndex > -1) {
        items = items.filter((el)=> {
          return el.ID !== this.editedItem.ID
        })
      }

      items.forEach((el) => {
        if(this.editedItem.branch_name.toLowerCase() === el.branch_name.toLowerCase()) {
          existProps.push('Branch Name: '+this.editedItem.branch_name)
        }
        if(this.editedItem.branch_code.toLowerCase() === el.branch_code.toLowerCase()) {
          existProps.push('Branch Code: '+this.editedItem.branch_code)
        }
        // if(this.editedItem.branch_telephone.toLowerCase() === el.branch_telephone.toLowerCase()) {
        //   existProps.push('Branch Telephone: '+this.editedItem.branch_telephone)
        // }
        // if(this.editedItem.branch_email.toLowerCase() === el.branch_email.toLowerCase()) {
        //   existProps.push('Branch Email: '+this.editedItem.branch_email)
        // }
      })
      if(existProps.length > 0) {
       this.$toast.warning(msg+existProps.join(', '), '', {position: 'topCenter', timeout: 7000})
        return true
      } 

      return false      
    },

    resetState() {
      this.SHOW_UNEXPECTED_ERROR_DIALOG(false)
    }
  }
}
</script>

 
